<template>
    <v-app id="view_invoice">

        <!-- Invoice header -->
        <v-card flat>
            <v-card-title>
                <v-flex text-xs-center>
                    <h3 class="text-center font-weight-bold header-space">
                        View Invoice

                        <v-btn
                            class="page_close_btn"
                            icon
                            to="/calendar"
                        >
                            <v-icon color="black" large>mdi-close</v-icon>
                        </v-btn>

                    </h3>
                </v-flex>
            </v-card-title>
        </v-card>
        <!-- End header -->

        <v-divider></v-divider>

        <v-row no-gutters>
            <!-- Left block -->
            <v-col cols="12" sm="6" md="8">
                <v-sheet height="690" class="grey lighten-4" style="overflow-y: scroll;">

                    <!-- Invoice details -->
                    <div class="pt-7 pa-10">
                        
                        <div v-if="voucher_exists" class="align-center justify-center d-flex">
                            <div class="tab_starts">
                                <div class="tab-content">
                                    <div class="d-flex" style="z-index: 1;width: 100%;">
                                        <button @click="voucher_tab_val = 1" v-bind:class="[voucher_tab_val == 1 ? 'active_tab_btn' : '']" class="tab_btn">Voucher</button>
                                        <button @click="voucher_tab_val = 0" v-bind:class="[voucher_tab_val == 0 ? 'active_tab_btn' : '']" class="tab_btn">Invoice</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <v-card
                            max-width="470"
                            class="mx-auto pa-8"
                            elevation="3"
                            tile
                            v-if="voucher_tab_val == 0"
                        >
                            <div class="text-center mt-3 mb-8">
                                <p class="text-h6 font-weight-regular mb-2">Invoice <span style="letter-spacing: 0px;">#{{ invoice_id }}</span></p>
                                <p class="text-body-2 blue-grey--text text--lighten-1">{{ moment(invoice_date).format('dddd, D MMM Y') }}</p>
                            </div>

                            <v-divider></v-divider>

                            <div class="py-4">
                                <template v-for="(item, i) in invoice_details">
                                    <div class="py-2" :key="'inv_det_'+i">
                                        <div class="text-body-2 blue-grey--text text--lighten-1">{{ item.qty }} item</div>
                                        <div class="d-flex flex-row flex-grow-1">
                                            <div class="d-flex flex-column flex-grow-1">
                                                <div class="text-body-1">{{ item.service }}</div>
                                                <div class="text-body-2 blue-grey--text text--lighten-1">
                                                    {{ moment(item.from_time, ["HH:mm:ss"]).format('h:mma') }}, 
                                                    {{ moment(item.date).format('D MMM Y') }} with {{ item.staff_name }}</div>
                                            </div>
                                            <div class="d-flex flex-column text-body-1 justify-center">
                                                {{ $currency }}{{ item.rate }}
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>

                            <v-divider></v-divider>

                            <div class="py-5 text-body-1">
                                <div class="d-flex flex-row flex-grow-1">
                                    <div class="d-flex flex-column flex-grow-1">
                                        Subtotal
                                    </div>
                                    <div class="d-flex flex-column">
                                        {{ $currency }}{{ invoice_sub_total }}
                                    </div>
                                </div>
                            </div>

                            <v-divider></v-divider>

                            <div class="py-5 text-body-1">
                                <div class="d-flex flex-row flex-grow-1">
                                    <div class="d-flex flex-column flex-grow-1">
                                        Total
                                    </div>
                                    <div class="d-flex flex-column">
                                        {{ $currency }}{{ invoice_total }}
                                    </div>
                                </div>
                            </div>

                            <v-divider v-if="payments.length > 0 || tips != ''"></v-divider>

                            <div v-if="tips != '' || payments.length > 0" class="py-5">
                                <div v-if="tips != ''">
                                    <div class="py-2 d-flex flex-row flex-grow-1">
                                        <div class="d-flex flex-column flex-grow-1">
                                            <div class="text-body-1">Tips</div>
                                            <!-- <div class="text-body-2 blue-grey--text text--lighten-1">Saturday, 26 Jun 2021 at 10:12pm</div> -->
                                        </div>
                                        <div class="d-flex flex-column text-body-1 justify-center">
                                            {{ $currency }}{{ tips }}
                                        </div>
                                    </div>
                                </div>

                                <div v-if="payments.length > 0">
                                    <div v-for="(item, i) in payments" :key="'pm_c'+i" class="py-2 d-flex flex-row flex-grow-1">
                                        <div class="d-flex flex-column flex-grow-1">
                                            <div class="text-body-1">{{ item.mode_of_payment }}</div>
                                            <!-- <div class="text-body-2 blue-grey--text text--lighten-1">Saturday, 26 Jun 2021 at 10:12pm</div> -->
                                        </div>
                                        <div class="d-flex flex-column text-body-1 justify-center">
                                            {{ $currency }}{{ item.amount }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <v-divider></v-divider>

                            <div class="py-5 text-body-1">
                                <div class="d-flex flex-row flex-grow-1">
                                    <div class="d-flex flex-column flex-grow-1">
                                        Balance
                                    </div>
                                    <div class="d-flex flex-column">
                                        {{ $currency }}{{ balance }}
                                    </div>
                                </div>
                            </div>

                            <div v-if="invoice_notes" class="py-1">
                                <div class="text-body-2 blue-grey--text text--lighten-1">
                                    {{ invoice_notes }}
                                </div>
                            </div>

                        </v-card>

                        <v-card 
                            elevation="0"
                            max-width="418"
                            class="mx-auto pa-8 text-center"
                            v-if="voucher_tab_val == 1"
                            style="background: linear-gradient(-225deg, #0b6dd9 0%, #5fabff 100%);border-radius:8px !important;color:#ffffff"
                        >
                            <div class="text-center">
                                <v-icon size="80px" style="border-radius: 4px;border: 2px solid #e8e8ee;">$vuetify.icons.LocationAvatarSvg</v-icon>

                                <p class="text-h6 font-weight-bold mt-4 mb-1">Ness Salon</p>
                                <p class="text-subtitle-1" style="line-height: 22px;">Ahmedabad - Palanpur Highway Road, Siddhpur (Madhav Nagar), Gujarat</p>

                                <v-divider style="border-color: #e7e8e94d" class="mt-9 mb-8"></v-divider>

                                <div class="voucher-value">
                                    <p class="text-subtitle-1 mb-2" style="line-height: 22px;">Voucher value</p>
                                    <p class="text-h5 font-weight-bold" style="font-size: 1.8rem !important;">{{ $currency }}100</p>
                                </div>

                                <v-divider style="border-color: #e7e8e94d" class="mt-8 mb-7"></v-divider>

                                <div class="text-center">
                                    <p class="text-subtitle-1">Voucher code: <span class="font-weight-medium">VMLPZZNR</span></p>
                                    <p class="text-subtitle-1 mb-0">Redeem on all services <v-icon color="white">mdi-chevron-right</v-icon></p>
                                    <p class="text-subtitle-1 mb-0">Valid until 5 Feb 2022</p>
                                    <p class="text-subtitle-1 mb-0">For multiple-use</p>
                                </div>
                            </div>
                            
                        </v-card>
                    </div>
                    <!-- End invoice details -->
                </v-sheet>
            </v-col>
            <!-- End left block -->

            <!-- Right block -->
            <v-col cols="6" md="4">
                <v-card flat class="pa-7 pb-5">
                    <div>
                        <v-avatar
                            color="grey lighten-2"
                            size="60"
                            class="mr-3"
                        >
                            <span style="width: 32px;height: 32px;">
                                <svg viewBox="0 0 20 22" xmlns="http://www.w3.org/2000/svg"><g fill="#FFF" stroke="#101928"><path d="M.5 21c0-5.5313 4.1043-9.5 9.5-9.5s9.5 3.9687 9.5 9.5v.5H.5V21z" stroke-linecap="round" stroke-linejoin="round"></path><path d="M15.5 6c0-3.0374-2.4626-5.5-5.5-5.5S4.5 2.9626 4.5 6s2.4626 5.5 5.5 5.5 5.5-2.4626 5.5-5.5z"></path></g></svg>
                            </span>
                        </v-avatar>

                        <span class="text-h6 font-weight-medium">
                            {{ customer }}
                        </span>
                    </div>
                </v-card>

                <v-divider></v-divider>

                <v-card
                    color="white"
                    flat
                    tile
                > 
                <!-- height="422" -->
                    <v-container class="px-15" fluid fill-height>
                        <!-- align-center justify-center -->
                        <v-layout v-if="paid_status == 'Paid'" class="my-15 mb-12">
                            <div class="text-md-center">
                                <v-icon size="100px">$vuetify.icons.CompletedSvg</v-icon>
                                <p class="mt-4 text-h5 font-weight-bold">Completed</p>
                                <p class="mb-10">Full payment received on {{ moment(invoice_date).format('dddd, D MMM Y') }} by {{ payment_received_by }}</p>
                            </div>
                        </v-layout>

                        <v-layout v-else class="my-15 mb-16 align-center justify-center">
                            <div class="text-md-center">
                                <v-icon size="100px">$vuetify.icons.UnPaidInvoiceSvg</v-icon>
                                <p class="mt-4 mb-16 text-h5 font-weight-bold">Unpaid</p>
                            </div>
                        </v-layout>
                    </v-container>

                    <v-container class="px-6">
                        <label class="dialog_label">Send Invoice</label>
                        <v-row>
                            <v-col cols="9" class="pb-0">
                                <v-text-field
                                    solo
                                    placeholder="Email address"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="3" class="pl-0 pb-0">
                                <v-btn
                                    class="save_app font-weight-medium"
                                    large
                                    dark
                                    color="#101928"
                                    block
                                    height="49px"
                                >
                                    Send
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>

                <v-divider></v-divider>

                <!-- Checkout btn -->
                <v-card flat>
                    <div class="pa-4">
                        <v-row align="center">
                            <v-col
                                cols="6"
                            >
                                <div class="my-2 text-center">

                                    <v-menu top offset-y rounded="0">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn
                                                v-bind="attrs"
                                                v-on="on"
                                                large
                                                color="white"
                                                elevation="1"
                                                class="app_btn"
                                            >
                                                <span class="text-capitalize font-checkout">More options</span>
                                                <v-icon dense class="pl-3">mdi-chevron-down</v-icon>
                                            </v-btn>
                                        </template>
                                        <v-list class="pa-0" dense>
                                            <template v-for="(item, index) in more_options">
                                                <v-list-item
                                                    :key="index"
                                                    link
                                                    :class="item.text_color"
                                                    @click="more_option_click(item.label)"
                                                >
                                                    <v-list-item-title class="text-center app_font" link>
                                                        {{ item.label }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                                <v-divider
                                                    v-if="index < more_options.length - 1"
                                                    :key="item.value"
                                                ></v-divider>
                                            </template>
                                        </v-list>
                                    </v-menu>


                                </div>
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <div class="my-2 text-center">
                                    <v-btn
                                        class="text-capitalize font-weight-bold px-10 py-4 font-checkout"
                                        dark
                                        color="#101928"
                                        large
                                        depressed
                                        width="180px"
                                        v-if="paid_status == 'Paid'"
                                        :to="'/calendar'"
                                    >
                                        Close
                                    </v-btn>

                                    <v-btn
                                        class="text-capitalize font-weight-bold px-10 py-4 font-checkout"
                                        dark
                                        color="#101928"
                                        large
                                        depressed
                                        width="167px"
                                        v-else
                                        :to="'/apply_payment/'+invoice_id"
                                    >
                                        Pay Now
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </div>
                </v-card>
                <!-- End Checkout -->

            </v-col>
            <!-- End right block -->

        </v-row>
    </v-app>
</template>

<script>
import CommonApi from '@/services/CommonApi'
import moment from 'moment'
export default {
    name: "ViewInvoice",

    data: () => ({
        invoice_id : '',
        invoice_details : [],
        invoice_date : '',
        invoice_sub_total : '110',
        invoice_total : '110',
        payments : [],
        balance : '0',
        more_options: [
            { label: 'Rebook Appointment', text_color: ''  },
            { label: 'Download', text_color: ''  },
            { label: 'Print', text_color: ''  },
            { label: 'Refund', text_color: 'color-pink'  },
            { label: 'Void', text_color: 'color-pink'  },
        ],
        customer : '',
        paid_status: '',
        tips : '',
        invoice_notes : '',
        payment_received_by : '',
        moment : moment,
        voucher_exists : true,
        voucher_tab_val : 1
    }),
    mounted() {
        this.getInvoiceDetails();
    },
    methods : {
        more_option_click(val) {
            if(val == 1) {
                if(this.tips_list.length > 0) {
                    this.unpaid_tip_dialog = true;
                } else {
                    this.save_unpaid();
                }
            } else if(val == 2) {
                this.invoice_dialog = true;
            }
        },
        async getInvoiceDetails() {
            this.invoice_id = this.$route.params.invoice_id;

            let param = { invoice: this.invoice_id };
            let params = new URLSearchParams(param);
            await CommonApi.get_data('innosoft_salon.api.get_invoice_details', params)
            .then(res => {
                this.invoice_date = res.data.date;
                this.invoice_details = res.data.items;
                this.invoice_sub_total = res.data.net_total;
                this.invoice_total = res.data.grand_total;
                this.balance = res.data.balance_amount;
                this.payments = res.data.payments;
                this.customer = res.data.customer_name;
                this.paid_status = res.data.status;
                this.tips = res.data.tips;
                this.invoice_notes = res.data.invoice_note;
                this.payment_received_by = res.data.payment_received_by;
            })

        }
    }
}
</script>
